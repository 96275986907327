import styles from "./ManageTeacher.module.css";
import FloatingInput from "../UI/FloatingInput";
import validator from "validator";
import parsePhoneNumber from "libphonenumber-js";
import { forwardRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { add_user, queryClient, update_user } from "../../util/http";
import { DEFAULT_REGION, Toast } from "../../config";

const phoneFormatInternational = (phone) => {
    const phoneNumber = parsePhoneNumber(phone, {
        extract: false,
        defaultCountry: DEFAULT_REGION.countryCode,
    });
    if (!phoneNumber) return undefined;
    return phoneNumber.formatInternational();
};

const checkPhoneValidity = (phone) => {
    const phoneNumber = parsePhoneNumber(phone, {
        extract: false,
        defaultCountry: DEFAULT_REGION.countryCode,
    });
    if (!phoneNumber) return false;
    // if (DEFAULT_REGION.countryCode && phoneNumber.country !== DEFAULT_REGION.countryCode) return false;
    return phoneNumber.isValid();
};

const TeacherInfoForm = forwardRef(({ reset, editData = undefined }, ref) => {
    const editId = editData?.id;
    const initName = () => (editId && editData.name ? editData.name : "");
    const initEmail = () =>
        editId && editData.email
            ? { value: editData.email, isValid: true }
            : { value: "", isValid: undefined };
    const initPhone = () =>
        editId && editData.phone
            ? { value: editData.phone, isValid: true }
            : { value: "", isValid: undefined };
    const initUsername = () =>
        editId && editData.username
            ? { value: editData.username, isValid: true }
            : { value: "", isValid: undefined };
    const initPassword = () =>
        editId && editData.password
            ? { value: editData.password, isValid: true }
            : { value: "", isValid: undefined };
    const mutationFn = (data) =>
        editId ? update_user("teacher", data) : add_user("teacher", data);
    const mutationErrorHandler = (error) => {
        if (editId) {
            Toast.fire({
                title: "Failed to edit teacher",
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error while editing teacher", error);
        } else {
            Toast.fire({
                title: "Failed to add teacher",
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error while adding teacher", error);
        }
    };
    const mutationSuccessHandler = (response) => {
        const { username } = JSON.parse(response.config.data);
        if (editId) {
            Toast.fire({
                title: `Teacher ${username} edited! ✏️`,
                icon: "success",
            });
        } else {
            Toast.fire({
                title: `Teacher ${username} added! 🌟`,
                icon: "success",
            });
        }
        console.log(response);
        reset();
        queryClient.invalidateQueries({ queryKey: ["teachers"] });
    };

    const [name, setName] = useState(initName);
    const [email, setEmail] = useState(initEmail);
    const [phone, setPhone] = useState(initPhone);
    const [username, setUsername] = useState(initUsername);
    const [password, setPassword] = useState(initPassword);
    const [addButtonMouseOver, setAddButtonMouseOver] = useState(false);

    const { mutate, isPending, isPaused } = useMutation({
        mutationFn: mutationFn,
        onError: mutationErrorHandler,
        onSuccess: mutationSuccessHandler,
    });

    const formSubmitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
            !email.isValid ||
            !phone.isValid ||
            !username.isValid ||
            !password.isValid
        )
            return;

        const payload = {
            name: name,
            email: email.value,
            phone_number: phone.value,
            username: username.value,
            password: password.value,
        };
        if (editId) payload.user_id = editId;
        console.log(payload);
        mutate(payload);
    };

    const phoneChangeHandler = (e) => {
        setPhone({
            value: e.target.value,
            isValid: checkPhoneValidity(e.target.value),
        });
    };

    const phoneBlurHandler = () => {
        setPhone((prevState) => {
            if (prevState.isValid) {
                return {
                    ...prevState,
                    value: phoneFormatInternational(prevState.value),
                };
            } else return prevState;
        });
    };

    const emailChangeHandler = (e) => {
        setEmail({
            value: e.target.value,
            isValid: validator.isEmail(e.target.value),
        });
    };

    const usernameChangeHandler = (e) => {
        const isValid =
            validator.isAlphanumeric(e.target.value, "en-US", {
                ignore: "_",
            }) || validator.isEmail(e.target.value);
        setUsername({ value: e.target.value, isValid: isValid });
    };

    const passwordChangeHandler = (e) => {
        const passwordIsValid = validator.isStrongPassword(e.target.value, {
            minLength: 6,
            minUppercase: 1,
            minSymbols: 1,
            minNumbers: 0,
        });
        setPassword({ value: e.target.value, isValid: passwordIsValid });
    };

    return (
        <div ref={ref} className={`card card-bg-1 rounded-3 border-2 my-4`}>
            <div className="card-body">
                <h4 className="card-title text-primary ">
                    {editId ? "Edit" : "Add"} teacher
                </h4>
                <form onSubmit={formSubmitHandler}>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl mb-3">
                            <FloatingInput
                                label="Name"
                                type="text"
                                id="teacherInfoName"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-6 col-md-7 col-xl-3 mb-3">
                            <FloatingInput
                                label="Email"
                                type="email"
                                id="teacherInfoEmail"
                                value={email.value}
                                onChange={emailChangeHandler}
                                valid={email.isValid}
                            />
                        </div>
                        <div className="col-6 col-md-4 col-xl-2 mb-3">
                            <FloatingInput
                                label="Phone"
                                type="tel"
                                id="teacherInfoPhone"
                                value={phone.value}
                                onChange={phoneChangeHandler}
                                onBlur={phoneBlurHandler}
                                valid={phone.isValid}
                            />
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <FloatingInput
                                label="Username"
                                type="text"
                                id="teacherInfoUsername"
                                value={username.value}
                                onChange={usernameChangeHandler}
                                valid={username.isValid}
                            />
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <FloatingInput
                                label="Password"
                                type="text"
                                id="teacherInfoPassword"
                                value={password.value}
                                onChange={passwordChangeHandler}
                                valid={password.isValid}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <button
                                className={`btn btn-secondary me-2 ${styles.button}`}
                                type="button"
                                onClick={reset}
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                className={`btn btn-primary ${styles.button}`}
                                onMouseEnter={(e) =>
                                    setAddButtonMouseOver(true)
                                }
                                onMouseLeave={(e) =>
                                    setAddButtonMouseOver(false)
                                }
                                disabled={isPending}
                            >
                                {!isPending ? (
                                    <>
                                        <i
                                            className={`fa-solid ${
                                                editId
                                                    ? "fa-pencil fa-sm"
                                                    : "fa-plus"
                                            } ${
                                                addButtonMouseOver && "fa-beat"
                                            }`}
                                            style={{
                                                verticalAlign: "-0.06rem",
                                            }}
                                        ></i>{" "}
                                        {editId ? "Edit" : "Add"} teacher
                                    </>
                                ) : !isPaused ? (
                                    editId ? (
                                        "Editing..."
                                    ) : (
                                        "Adding..."
                                    )
                                ) : (
                                    "Offline"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default TeacherInfoForm;
