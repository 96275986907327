import styles from "./Login.module.css";
import logoShort from "../assets/images/logo-short.png";
import { useContext, useLayoutEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import StyleClassContext from "../store/StylesContext";
import { user_login } from "../util/http";
import { Alert } from "../config";
import { setAuthData } from "../util/auth";
import { useNavigate, useRevalidator } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const {revalidate} = useRevalidator();

    const setPageBackgroundClass = useContext(StyleClassContext);
    useLayoutEffect(() => {
        setPageBackgroundClass(styles.bg);
        return () => {
            setPageBackgroundClass("");
        };
    }, [setPageBackgroundClass]);

    const { mutate, isPending, isPaused } = useMutation({
        mutationFn: user_login,
        onError: (error) => {
            Alert.fire({
                title: "Unable to authenticate...",
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
        },
        onSuccess: (response) => {
            // console.log("logged in", response);
            setAuthData(response.data.data);
            revalidate();
            navigate("/");
        },
    });

    const handleFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        mutate({ username: username, password: password });
    };

    return (
        <div className="row h-100">
            <div
                className={`${styles["form-signin"]} col mx-auto align-self-center`}
            >
                <form onSubmit={handleFormSubmit}>
                    <img
                        className="mb-4"
                        src={logoShort}
                        alt=""
                        width="60"
                        height="57"
                    />
                    <h3 className="mb-3" style={{ color: "var(--brinjal)" }}>
                        Login to school portal
                    </h3>

                    <div className={`form-floating ${styles["form-floating"]}`}>
                        <input
                            type="text"
                            className="form-control"
                            id={styles.formInputUsername}
                            placeholder="name@example.com or us"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                            required
                            tabIndex={1}
                            autoFocus
                        />
                        <label htmlFor={styles.formInputUsername}>
                            Username
                        </label>
                    </div>
                    <div className={`form-floating ${styles["form-floating"]}`}>
                        <input
                            type="password"
                            className="form-control"
                            id={styles.formInputPassword}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            required
                            tabIndex={2}
                        />
                        <label htmlFor={styles.formInputPassword}>
                            Password
                        </label>
                    </div>

                    <div className="form-check text-start my-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="remember-me"
                            id="flexCheckDefault"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                        >
                            Remember me
                        </label>
                    </div>
                    <button
                        className="btn btn-primary w-100 py-2"
                        type="submit"
                        disabled={isPending}
                    >
                        {!isPending
                            ? "Login"
                            : !isPaused
                            ? "Authenticating..."
                            : "Offline"}
                    </button>
                    <p className="mt-5 text-body-secondary">© 2017-{new Date().getFullYear()}</p>
                </form>
            </div>
        </div>
    );
};

export default Login;
